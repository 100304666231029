import _defineProperty from "@babel/runtime/helpers/defineProperty";
import { pxToRem } from '@pwa/ui';
import { alpha, Box, styled } from '@mui/material';
var StyledBox = styled(Box)(function (_ref) {
  var _styles;

  var theme = _ref.theme;
  var styles = (_styles = {}, _defineProperty(_styles, theme.breakpoints.down('desktop'), {
    background: "linear-gradient(180deg, rgba(247, 248, 252, 0.00) 0%, ".concat(theme.colors.ghostWhite, " 16%)"),
    bottom: '0',
    paddingTop: theme.spacing(7.5),
    position: 'absolute',
    width: '100%'
  }), _defineProperty(_styles, "paddingBottom", theme.spacing(4)), _defineProperty(_styles, "paddingTop", theme.spacing(4)), _styles);
  return styles;
});
var StyledPageWrapper = styled(Box)(function (_ref2) {
  var theme = _ref2.theme,
      $isUpgradePackage = _ref2.$isUpgradePackage,
      $isHasHeaderLayout = _ref2.$isHasHeaderLayout;
  var height = "calc(".concat(theme.convertToDVH(100), " - ").concat($isUpgradePackage || $isHasHeaderLayout ? theme.HEADER_HEIGHT : '0px', ")");

  var styles = _defineProperty({}, theme.breakpoints.down('desktop'), {
    display: 'flex',
    flexDirection: 'column',
    height: height
  });

  return styles;
});
var StyledContent = styled(Box)(function (_ref3) {
  var _styles3;

  var theme = _ref3.theme;
  var styles = (_styles3 = {}, _defineProperty(_styles3, theme.breakpoints.down('desktop'), {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: '1',
    // justifyContent: 'space-between',
    overflow: 'hidden'
  }), _defineProperty(_styles3, "position", 'relative'), _styles3);
  return styles;
});
var StyledInfo = styled(Box)(function (_ref4) {
  var theme = _ref4.theme;

  var styles = _defineProperty({}, theme.breakpoints.down('desktop'), {
    overflowX: 'hidden',
    overflowY: 'scroll',
    paddingBottom: theme.spacing(24.75)
  });

  return styles;
});
var StyledImageWrapper = styled(Box)(function (_ref5) {
  var theme = _ref5.theme;

  var styles = _defineProperty({
    '& img': {
      borderRadius: pxToRem(10),
      height: '100%',
      objectFit: 'cover',
      width: '100%'
    },
    marginLeft: theme.spacing(9.75),
    marginRight: theme.spacing(9.75),
    maxWidth: pxToRem(300),
    position: 'relative'
  }, theme.breakpoints.up(456), {
    margin: 'auto'
  });

  return styles;
});
var StyledShadow = styled(Box)(function (_ref6) {
  var theme = _ref6.theme;
  var styles = {
    backgroundColor: alpha(theme.colors.black, 0.2),
    borderRadius: 16,
    bottom: '-3%',
    filter: "blur(".concat(theme.typography.pxToRem(12.5), ")"),
    height: '6%',
    left: '-10%',
    pointerEvents: 'none',
    position: 'absolute',
    right: '-10%'
  };
  return styles;
});
var StyledSliderNavigation = styled(Box)(function (_ref7) {
  var _styles6;

  var theme = _ref7.theme;
  var styles = (_styles6 = {}, _defineProperty(_styles6, theme.breakpoints.up(456), {
    '&> button.arrow-left': {
      left: 'unset',
      right: '100%',
      transform: "translateX(-".concat(theme.typography.pxToRem(16), ")")
    },
    '&> button.arrow-right': {
      left: '100%',
      right: 'unset',
      transform: "translateX(".concat(theme.typography.pxToRem(16), ")")
    },
    left: '50%',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: pxToRem(300),
    position: 'absolute',
    top: '50%',
    transform: 'translateX(-50%)',
    width: '100%',
    zIndex: 1
  }), _defineProperty(_styles6, theme.breakpoints.up('tablet'), {
    '&> .arrow-left': {
      transform: "translateX(-".concat(theme.typography.pxToRem(24), ")")
    },
    '&> .arrow-right': {
      transform: "translateX(".concat(theme.typography.pxToRem(24), ")")
    }
  }), _styles6);
  return styles;
});
var StyledDesktopContent = styled(Box)(function (_ref8) {
  var theme = _ref8.theme;

  var styles = _defineProperty({}, theme.breakpoints.up('desktop'), {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: theme.typography.pxToRem(452)
  });

  return styles;
});
export { StyledBox, StyledContent, StyledDesktopContent, StyledImageWrapper, StyledInfo, StyledPageWrapper, StyledShadow, StyledSliderNavigation };